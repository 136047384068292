import {getRequest,postRequest,putRequest,deleteRequest} from "@/utils/request_utils"

export default {
  getForiegnList: (params)=> {
    return getRequest(`/api-base/foreign/pageList`,{params});
  },
  saveForiegn: (data) => {
    return postRequest(`/api-base/foreign/save`,{data});
  },
  removeForiegn: (data)=> {
    return postRequest(`/api-base/foreign/delete`, {data})
  }
}
