<template>
  <div class="app-container">
    <div class="piece">
      <div class="blockTitle">基本信息</div>
      <div>
        <el-form
            v-if="formInline"
            ref="ruleForm"
            :model="formInline"
            class="demo-form-inline"
            label-width="110px"
            size="mini"
        >
          <el-row :gutter="20">
            <el-col :span="12">
              <el-form-item prop="merchantsName">
                <template slot="label">
                  <span class="star">*</span>
                  <label>{{ merchantsType ? '供应商名称' :'客户名称' }}</label>
                </template>
                <el-input v-model="formInline.merchantsName" size="mini" class="formInput" :placeholder="merchantsType ? '请输入供应商名称' :'请输入客户名称'"/>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item prop="merchantsCode" :label="merchantsType ? '供应商编码' :'客户编码'">
                <template slot="label">
                  <span class="star">*</span>
                  <label>{{merchantsType ? '供应商编码' :'客户编码'}}</label>
                </template>
                <el-input v-model="formInline.merchantsCode" class="formInput" disabled :placeholder="merchantsType ? '请输入供应商编码' :'请输入客户编码'"/>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item prop="identificationCode" label="纳税人识别码">
                <template slot="label">
                  <span class="star">*</span>
                  <label>纳税人识别码</label>
                </template>
                <el-input v-model="formInline.identificationCode" class="formInput" placeholder="请输入纳税人识别码"/>
              </el-form-item>
            </el-col>
            <!-- <el-col :span="12">
              <el-form-item prop="status" label="是否启用">
                <template slot="label">
                  <span class="star">*</span>
                  <label>是否启用</label>
                </template>
                <el-select v-model="formInline.status" placeholder="请选择是否启用">
                  <el-option
                      v-for="item in options"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value"
                  />
                </el-select>
              </el-form-item>
            </el-col> -->
            <el-col :span="12">
              <el-form-item prop="zhangqi" label="账期">
                <template slot="label">
                  <span class="star">*</span>
                  <label>账期</label>
                </template>
                <el-input v-model="formInline.zhangqi" oninput="value=value.replace(/[^\d]/g,'')" type="number"
                          class="formInput" minlength="1" maxlength="8" placeholder="请输入账期">
                  <template slot="append"> 天</template>
                </el-input>
              </el-form-item>
            </el-col>
            <!--            <el-col :span="12">-->
            <!--              <el-form-item label="本账户余额">-->
            <!--                <el-radio-group v-model="formInline.isIt">-->
            <!--                  <el-radio :label="0">不允许使用</el-radio>-->
            <!--                  <el-radio :label="1">允许使用</el-radio>-->
            <!--                </el-radio-group>-->
            <!--              </el-form-item>-->
            <!--            </el-col>-->
            <el-col :span="12">
              <el-form-item label="客商类型">
                <el-select size="mini" v-model="formInline.merchantsType" :disabled="true">
                  <el-option :label="'客户'" :value="'0'"></el-option>
                  <el-option :label="'供应商'" :value="'1'"></el-option>
                </el-select>
              </el-form-item>
            </el-col>

          </el-row>
        </el-form>
      </div>
    </div>
    <div class="piece">
      <el-row>
        <el-col :span="22">
          <div class="blockTitle">银行账户信息</div>
        </el-col>
        <el-col :span="2">
          <el-button type="primary" size="mini" @click="handleAdd">添 加</el-button>
        </el-col>
      </el-row>
      <el-form ref="formDomA" :model="formData" class="demo-ruleForm" size="mini">
        <el-table
            size="mini"
            border
            ref="mytable"
            :data="formData.tableData"
            :header-cell-style="{background: '#f6f6f6'}">
          <el-table-column
              prop="accountCode"
              label="账号"
              min-width="180"
              align="center"
          >
            <template slot-scope="scope">
              <el-form-item
                  v-if="scope.row.edit"
                  :prop="'tableData.' + scope.$index + '.accountCode'"
                  :rules="[{required:true,validator: validatorNumber}]"
              >
                <div>
                  <el-input v-model="scope.row.accountCode" size="mini" class="formInput" placeholder="账号"/>
                </div>
              </el-form-item>
              <p v-else style="margin-left: 10px">{{ scope.row.accountCode }}</p>
            </template>
          </el-table-column>
          <el-table-column
              label="账户"
              align="center"
              min-width="180"
          >
            <template slot-scope="scope">
              <el-form-item
                  v-if="scope.row.edit"
                  :prop="'tableData.' + scope.$index + '.accountName'"
              >
                <el-input v-model="scope.row.accountName" size="mini" class="formInput" placeholder="账户"/>
              </el-form-item>
              <p v-else>{{ scope.row.accountName }}</p>
            </template>
          </el-table-column>
          <el-table-column
              label="开户行名称"
              align="center"
              min-width="300"
          >
            <template slot-scope="scope">
              <el-form-item
                  v-if="scope.row.edit"
                  :prop="'tableData.' + scope.$index + '.bankName'"
              >
                <el-select
                    size="mini"
                    v-model="scope.row.bankName"
                    style="width:100%"
                    filterable
                    remote
                    reserve-keyword
                    placeholder="请输入支行名称，如海尔路支行"
                    :remote-method="findEntList"
                    @change="handleSelect(scope.row,scope.$index)">
                  <el-option
                      v-for="item in selectEnterpriseList"
                      :key="item.bankName"
                      :label="item.bankName"
                      :value="item.bankName">
                  </el-option>
                </el-select>
              </el-form-item>
              <p v-else>{{ scope.row.bankName }}</p>
            </template>
          </el-table-column>
          <el-table-column
              style="padding-bottom: 0"
              label="联行号"
              align="center"
              min-width="240"
          >
            <template slot-scope="scope">
              <el-form-item
                  v-if="scope.row.edit"
                  :prop="'tableData.' + scope.$index + '.bankCode'"
              >
<!--                <el-input v-model="scope.row.bankCode" size="mini" class="formInput" readonly-->
<!--                          placeholder="请正确选择支行名称会自动带出联行号"/>-->
                <el-select
                    size="mini"
                    v-model="scope.row.bankCode"
                    style="width:100%"
                    filterable
                    remote
                    reserve-keyword
                    placeholder="请输入开户行代码"
                    :remote-method="findEnterpriseCodeList"
                    @change="handleCodeSelect(scope.row,scope.$index,2)">
                  <el-option
                      v-for="item in selectEnterpriseCodeList"
                      :key="item.bankBramchCode"
                      :label="item.bankBramchCode"
                      :value="item.bankBramchCode">
                    <span style="float: left">{{ item.bankBramchCode }}</span>
                    <span style="float: right; color: #8492a6; font-size: 12px">{{ item.bankName }}</span>
                  </el-option>
                </el-select>
              </el-form-item>
              <p v-else>{{ scope.row.bankCode }}</p>
            </template>
          </el-table-column>
          <el-table-column
              style="padding-bottom: 0"
              prop="bank"
              label="总行"
              width="160"
              align="center">
            <template #default="scope">
              <el-form-item
                  :prop="'tableData.' + scope.$index + '.bankCategory'"
                  :rules="[{required:true,message:'请选择'}]">
                <el-select v-model="scope.row.bankCategory" size="mini" filterable clearable :disabled="!scope.row.edit"
                           @change="bankChange($event,scope.$index)">
                  <el-option v-for="item in bankTolList" :key="item.banktyp" :label="item.banknam"
                             :value="item.banktyp"></el-option>
                </el-select>
              </el-form-item>
            </template>
          </el-table-column>
          <el-table-column
              label="默认"
              width="60"
              align="center"
          >
            <template slot-scope="scope">
              <input type="radio" name="def" :checked="scope.row.status===0"
                     @change="defaultChange(scope.$index, scope.row)">
            </template>
          </el-table-column>
          <el-table-column label="操作" min-width="160" align="center">
            <template slot-scope="scope">
              <el-button
                  size="mini"
                  type="text"
                  @click="handleEdit(scope.$index, scope.row)"
              >编辑
              </el-button>
              <el-button
                  size="mini"
                  type="text"
                  :disabled="scope.row.status==0"
                  @click="handleDelete(scope.$index, scope.row)"
              >删除
              </el-button>
            </template>
          </el-table-column>
        </el-table>
      </el-form>
    </div>
    <div class="bottom-button">
      <el-button size="mini" type="primary" @click="saveBtnFun" :loading="loading">提 交</el-button>
      <el-button size="mini" @click="goback">取 消</el-button>
    </div>
  </div>
</template>

<script>
import service from '@/api/company-foriegn'
import companyService from '@/api/company';
// import Template from '@/views/electronicTag/template'

export default {
  // components: {Template},
  // components: { Template },
  data() {
    return {
      selectEnterpriseList: [],
      selectEnterpriseCodeList: [],
      routerrr: this.$route.query.number,
      activeName: 'first',
      tabsFrom: 'first',
      formInline: {
        merchantsType: '',
        id: '',
        merchantsCode: '',
        merchantsName: '',
        identificationCode: '',
        // status: 0,
        zhangqi: 0,
        companyAccountInfo: {
          accountName: '',
          accountCode: '',
          bankName: '',
          bankCode: ''
        },
        isIt: 0,
      },
      loading: false,
      bankTolList: [],
      rules: {
        zhangqi: [
          {required: true, message: '不能为空', trigger: 'blur'}
        ],
        merchantsCode: [
          {required: true, message: '不能为空', trigger: 'blur'}
        ],
        merchantsName: [
          {required: true, message: '不能为空', trigger: 'blur'}
        ],
        identificationCode: [
          {required: true, message: '不能为空', trigger: 'blur'}
        ],
        // status: [
        //   {required: true, message: '不能为空', trigger: 'change'}
        // ]
      },
      // options: [{
      //   value: 0,
      //   label: '启用'
      // }, {
      //   value: 1,
      //   label: '禁用'
      // }],
      formData: {
        rules: {
          accountCode: {
            type: 'string',
            required: true,
            message: '必填字段',
            trigger: 'blur'
          },
          accountName: {
            type: 'string',
            required: true,
            message: '必填字段',
            trigger: 'blur'
          },
          bankName: {
            type: 'string',
            required: true,
            message: '必填字段',
            trigger: 'blur'
          },
          bankCode: {
            type: 'string',
            required: true,
            message: '必填字段',
            trigger: 'blur'
          }
        },
        tableData: []
      },
      merchantsType: null
    }
  },
  mounted() {
    // if (this.$route.query.number) this.searchData()
    // this.handleAdd()
    // this.defaultChange(0, this.formData.tableData[0])
    if (this.$route.query.number) {
      this.getCustomerInfo();
    } else {
      // 不是修改查询客商编码应取的值
      this.getNewMerchantsCode()
    }
    this.formInline.merchantsType = this.$route.name === 'customerMerchantsInfoEdit-user' ? '0' : '1';
    this.getBankTo();
    this.merchantsType = this.$route.name === 'customerMerchantsInfoEdit-user' ? 0 : 1;  // 0 用户  1 供应商
  },
  methods: {
    //检验银行卡号
    validatorNumber(rule,value,callback){
      let reg = /^[0-9]*$/;
      if(value === ''){
        callback(new Error('请输入银行卡号'));
      }else if(reg.test(value) && value.indexOf(' ') === -1){
        callback();
      }else {
        callback(new Error('无效的格式'))
      }
    },
    getCustomerInfo() {
      companyService.getCustomerInfo({id: this.$route.query.number}).then(resp => {
        if (resp.success) {
          this.formInline = {
            merchantsType: resp.data.merchantsType,
            id: resp.data.id,
            merchantsCode: resp.data.merchantsCode,
            merchantsName: resp.data.merchantsName,
            identificationCode: resp.data.identificationCode,
            // status: resp.data.status,
            zhangqi: resp.data.zhangqi,
            companyAccountInfo: {
              accountName: '',
              accountCode: '',
              bankName: '',
              bankCode: ''
            },
          }
          this.formData.tableData = resp.data.foreignBankVos
        } else {
          this.$message.error(resp.message)
        }
      })
    },
    // 查询客商编码
    getNewMerchantsCode() {
      let merchantsType = this.$route.name === 'customerMerchantsInfoEdit-user' ? '0' : '1';
      companyService.getMerchantsCode({merchantsType}).then(res => {
        this.formInline.merchantsCode = res.data || ''
      }).catch(e => {
        console.log(e)
      })
    },
    getBankTo() {
      companyService.getBankTo({}).then(resp => {
        this.bankTolList = resp.data;
      })
    },
    bankChange(val, index) {
      let obj = this.bankTolList.find(f => f.banktyp === val);
      if (obj) {
        this.formData.tableData[index].bank = obj.banknam
      }
    },
    searchData() {
      searchBase(this.$route.query.number).then(data => {
        if (data.companyAccountInfoList.length === 0) {
          data.companyAccountInfo = {
            accountCode: '',
            accountName: '',
            bankCode: '',
            bankName: ''
          }
        } else {
          data.companyAccountInfoList.forEach(item => {
            item.edit = false
            if (item.status === 0) data.companyAccountInfo = item
          })
        }

        this.formInline = data
        this.formData.tableData = data.companyAccountInfoList
      })
    },
    handleSelect(item, index) {
      let selectEnterpriseList = this.selectEnterpriseList;
      let thisBank = selectEnterpriseList.filter(obj => {
        return obj.bankName === item.bankName
      });
      this.formData.tableData[index].bankCode =thisBank.length> 0 && thisBank[0].bankBramchCode
      this.formInline.companyAccountInfo.status = 0
      this.formInline.companyAccountInfo.true = false
    },
    handleCodeSelect(item, index, met){
      let selectEnterpriseList = this.selectEnterpriseCodeList;
      let thisBank = selectEnterpriseList.filter(obj => {
        return obj.bankBramchCode === item.bankCode
      });
      this.formData.tableData[index].bankName =thisBank.length> 0 && thisBank[0].bankName
      this.formInline.companyAccountInfo.status = 0
      this.formInline.companyAccountInfo.true = false
    },

    async findEntList(queryString, cb) {
      if (queryString.length === 0) {
        queryString = ' '
      }
      companyService.getBank({bankName: queryString}).then(res => {
        if (res) {
          this.selectEnterpriseList = res.data.list
        } else {
          this.selectEnterpriseList = []
        }
      })
    },
    async findEnterpriseCodeList(queryString, cb){
      companyService.getBank({bankBramchCode: queryString}).then(res => {
        if (res) {
          this.selectEnterpriseCodeList = res.data.list
        } else {
          this.selectEnterpriseCodeList = []
        }
      })
    },
    saveBtnFun() {
      if (!this.formInline.merchantsName) {
        this.$message.warning('请完善名称')
        return
      }
      if (!this.formInline.merchantsCode) {
        this.$message.warning('请完善编码')
        return
      }
      if (!this.formInline.identificationCode) {
        this.$message.warning('请完善纳税人识别码')
        return
      }
      // if (!this.formInline.status && this.formInline.status !== 0) {
      //   this.$message.warning('请选择是否启用')
      //   return
      // }
      if (this.formInline.zhangqi === '' || this.formInline.zhangqi == null) {
        this.$message.warning('请完善账期')
        return
      }
      let empty = false
      this.formData.tableData.forEach(item => {
        Object.keys(item).some(key => {
          if (item[key] === '') {
            empty = true
          }
        })
      })
      if (empty) {
        this.$message.warning('请完善银行账户信息！')
        return
      }

      let data = JSON.parse(JSON.stringify(this.formInline))
      data.foreignBankVos = this.formData.tableData
      // if (data.foreignBankVos.length === 0) {
      //   data.foreignBankVos.push(data.companyAccountInfo)
      // }
      this.loading= true;
      service.saveForiegn(data).then(data => {
        if(data && data.success){
          this.$message.success('保存成功!')
          this.goback()
        }else {
          this.$message.error(data.message);
        }
      }).finally(() => {
        this.loading= false;
      })
    },
    goback() {
      this.$router.go(-1)
    },
    handleClick(tab, event) {
      if (this.tabsFrom === 'first' && tab.name !== 'first') {
        this.tabsFrom = tab.name
        this.$refs['ruleForm'].validate((valid) => {
          console.log(valid, 'valid')
          if (valid) {
            let p1 = new Promise((resolve, reject) => {
              this.$refs['ruleForm'].validate(valid => {
                if (valid) {
                  resolve()
                } else {
                  this.$message.error('请完善正确的信息')
                  return false
                }
              })
            })

            Promise.all([p1]).then(() => {
              let data = JSON.parse(JSON.stringify(this.formInline))
              data.companyAccountInfoList = this.formData.tableData
              if (data.companyAccountInfoList.length === 0) {
                console.log(data.companyAccountInfo)
                data.companyAccountInfoList.push(data.companyAccountInfo)
              }
              console.log(data)
              save(data).then(data => {
                searchIdent(this.formInline.merchantsCode).then(data => {
                  console.log(data)
                  this.formInline = data
                  console.log(this.formInline)
                  this.formData.tableData = data.companyAccountInfoList
                })
              })
            })
          } else {
            this.$nextTick(() => {
              this.activeName = this.tabsFrom = 'first'
            })
            this.$message.error('请完善正确的信息')
          }
        })
      } else {
        let p1 = new Promise((resolve, reject) => {
          this.$refs['ruleForm'].validate(valid => {
            if (valid) {
              resolve()
            } else {
              this.$message.error('请完善正确的信息')
              return false
            }
          })
        })
        let p2 = new Promise((resolve, reject) => {
          this.$refs['formDomA'].validate(valid => {
            if (valid) {
              resolve()
            } else {
              this.$message.error('请完善正确的信息')
              return false
            }
          })
        })
        Promise.all([p1, p2]).then(() => {
          let data = JSON.parse(JSON.stringify(this.formInline))
          data.companyAccountInfoList = this.formData.tableData
          delete data.companyAccountInfo
          save(data).then(data => {
          })
        })
      }
      // console.log(tab, event)
    },
    defaultChange(index, row) {
      row.status = 0
      this.formInline.companyAccountInfo = row
      this.formData.tableData.forEach((item, i) => {
        if (i !== index) item.status = 1
      })
      // console.log(this.formData.tableData)
    },
    handleAdd() {
      this.formData.tableData.push({
        accountCode: '',
        accountName: '',
        bankName: '',
        bankCode: '',
        status: this.formData.tableData.length === 0 ? 0 : 1,
        edit: true,
        bankCategory: '',
      })
    },
    handleEdit(index, row) {
      let list = [...this.formData.tableData]
      list[index].edit = true;
      this.$set(this.formData.tableData, list)
    },
    handleDelete(index, row) {
      this.$confirm('确定要删除此项？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        // if (row.id) {
        //   // console.log(row.id)
        //   // delAccount(row.id).then(data => {
        //   //   this.searchData()
        //   // })
        // } else {
        //   //this.formData.tableData.splice(index, 1)
        //   // this.searchData()
        // }
        this.formData.tableData.splice(index, 1)
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.fl {
  float: left
}

.fr {
  float: right
}

.clear {
  clear: both
}

.searchBar {
  padding-bottom: 20px;

  .title {
    line-height: 32px;
    margin-right: 10px;
  }
}

.btnBox {
  padding: 10px 20px;
  background: #fff;
  border-bottom: 1px solid #eee;
  margin-bottom: 10px;
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.3)
}

.btnBackColor {
  background: #409EFF;
  color: #fff;
  border-radius: 4px;
}

.activeName {
}

.formInput {
  width: 70%
}

.lineBox {
  .line {
    width: 100%;
    height: 1px;
    background: #ccc;
    margin-bottom: -11px;
  }

  text-align: center;
  padding-bottom: 11px;

  span {
    display: inline-block;
    background: #fff;
    font-size: 14px;
    padding: 0 10px;
    color: #333;
  }
}

.app-container {
  height: 100%;
  background-color: #f6f6f6;
}

.el-form {
  padding-top: 10px;

  .el-form-item {
    margin-left: 6%;
    margin-right: 6%;

    > > > .el-form-item__label {
      font-size: 12px;
    }
  }

  .el-select {
    width: 100%;
  }

  .el-input {
    width: 100%;
  }

  .el-autocomplete {
    width: 100%;
  }
}

.el-table {
  padding: 0 10px;
  margin-bottom: 15px;
}

> > > .el-table thead.is-group th {
  background: white !important;
}

> > > .el-table th.gutter {
  display: table-cell !important;
}

> > > .has-gutter {
  font-size: 12px;
}

.elTable .el-table__header tr,
> > > .el-table__header th {
  padding: 0 !important;
  height: 30px !important;
  background-color: #f6f6f6;
  font-weight: 500 !important;
}

.el-table--border {
  border-radius: 3px !important;
}
</style>
